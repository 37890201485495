@import 'styles/_variables.scss';

.result__header {
	display: grid;
	grid-column-start: 1;
	grid-column-end: 3;
	grid-column-gap: $spacing-huge;
	grid-template-columns: $result-columns;
	min-height: 2rem;
	line-height: 100%;

	& > * {
		font-size: $font-size-small;
		color: $color-gray-800;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
}
