@import 'styles/_variables.scss';

.result {
	&__container {
		display: grid;
		grid-template-columns: $result-columns;
		grid-auto-rows: max-content;
		width: 100%;
		grid-column-gap: $spacing-huge;
		opacity: 0.15;
		transition: 0.5s;
		filter: blur(5px);

		&:hover,
		&--inview {
			opacity: 1;
			filter: blur(0px);
		}
	}

	&__percentage {
		font-size: 3rem;
		font-weight: bold;

		&--embedded {
			display: none;
			grid-column-start: 1;
			grid-column-end: 3;
			flex-direction: row;
			justify-content: space-between;

			@media screen and (max-width: 768px) {
				display: flex;
				align-items: baseline;
				margin-bottom: 1rem;
				color: $color-gray-800;
			}

			& h1 {
				@media screen and (max-width: 768px) {
					color: black;
				}
			}
		}

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	&__content {
		line-height: $font-line-height-large;
		font-weight: 400;
		text-align: justify;

		@media screen and (max-width: 768px) {
			grid-column-start: 1;
			grid-column-end: 3;
			text-align: left;
		}
	}
}
