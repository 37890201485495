@import 'styles/_variables.scss';

.footnote__container {
	grid-column-start: 1;
	grid-column-end: 3;
	line-height: $font-line-height-large;

	& ul {
		padding-left: 0rem;
		line-height: calc(#{$font-line-height-large} * 2);

		@media screen and (max-width: 768px) {
			line-height: $font-line-height-medium;
		}
	}

	& li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		opacity: 0.3;

		&:not(:last-child) {
			border-bottom: 1px solid $color-gray-500;
		}

		&:hover {
			opacity: 1;
		}

		@media screen and (max-width: 768px) {
			flex-direction: column;
			gap: 0.5rem;
			opacity: 1;
			padding-top: $spacing-small;
			padding-bottom: $spacing-small;
		}
	}
}

.legal {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: $spacing-medium;
}
