.switcher {
	height: 0.6rem;
	width: calc(100vw / 50);
	margin: 0;
	padding: 0;
	background-color: blue;
	cursor: not-allowed;
	transition: 0.23s ease-in-out;
	filter: grayscale(100%);

	&:hover {
		height: 2rem;
	}
}
