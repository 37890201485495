@import 'styles/_variables.scss';

.links {
	&__container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: $spacing-large;
		padding-right: $spacing-large;
		position: fixed;
		left: 0;
		width: 100vw;
		top: calc(#{$spacing-large} + 1rem);
		align-items: center;
		box-sizing: border-box;
		z-index: 10000;
		transition: 0.4s;

		&--hidden {
			opacity: 0;
			filter: blur(2px);
		}

		& * {
			cursor: pointer;
		}

		& a {
			text-align: right;
		}
	}

	&__github {
		font-size: $font-size-medium;
		opacity: 0.3;
		transition: 0.2s ease-in-out;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $spacing-medium;

		&:hover {
			opacity: 1;
		}

		& p,
		& a {
			font-size: $font-size-small;
		}
	}
}
