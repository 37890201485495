@import './_variables.scss';

* {
	font-family: 'Roboto', sans-serif;
	cursor: default;
}

*::selection {
	background-color: $color-primary;
	color: white;
}

h1 {
	font-size: $font-size-large;
	font-family: 'Integral', serif;
	white-space: nowrap;
}

h2 {
	font-size: $font-size-medium;
	font-family: 'Integral', serif;
	white-space: nowrap;
}

h3 {
	font-size: $font-size-small;
	font-family: 'Integral', serif;
	font-weight: 200;
	white-space: nowrap;
}

hr {
	width: 100%;
	border-width: 0.35rem;
	border-color: $color-gray-400;
	border-style: solid;
	background-color: $color-gray-400;
}

a {
	color: $color-primary;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	opacity: 0.6;
}
