@import 'styles/_variables.scss';

.content__container {
	display: flex;
	background-color: white;
	flex-direction: column;
	position: absolute;
	top: calc((100% + #{$spacing-huge}) * 3);
	left: 0;
	width: 100%;
	gap: $spacing-huge;
	padding-bottom: $spacing-huge;
}
