@import 'styles/_variables.scss';

.meta {
	&__container {
		width: 100%;
		text-align: justify;

		> * {
			line-height: $font-line-height-large;
		}

		@media screen and (max-width: 768px) {
			text-align: left;
		}
	}

	&__warning {
		color: $color-primary;
		margin-right: 0.5rem;
		position: relative;
		top: 0.15em;
	}
}
