.colorswitch__container {
	width: 100vw;
	min-height: 1rem;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	display: flex;
	flex-direction: row;

	@media screen and (max-width: 768px) {
		display: none;
	}
}

.hint {
	position: fixed;
	left: 0;
	top: 2rem;
	z-index: 0;
	width: 100vw;
	min-height: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	transition: 0.4s;
	transition-delay: 0.25s;

	&__lead--hidden {
		opacity: 0;
		filter: blur(2px);
	}
}
