@import 'styles/_variables.scss';

.abstract__container {
	display: grid;
	grid-template-columns: 1fr;

	& > * {
		line-height: $font-line-height-large;
		font-weight: 400;
		text-align: justify;

		@media screen and (max-width: 768px) {
			text-align: left;
		}
	}
}
