@import 'styles/_variables.scss';

.result__heading {
	display: flex;
	grid-column-start: 1;
	grid-column-end: 3;
	gap: $spacing-medium;
	align-items: baseline;
	min-height: 3rem;
}
